<template>
  <a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Sales Overview</h6>
      <p>Grafik Penjualan Bulanan</p>
    </template>
    <!-- <template #extra>
			<a-badge color="primary" class="badge-dot-primary" text="Traffic" />
			<a-badge color="primary" class="badge-dot-secondary" text="Sales" />
		</template> -->
    <chart-line ref="chart" :height="370" :data="lineChartData"></chart-line>
  </a-card>
</template>

<script>
import axios from "axios";
// Bar chart for "Active Users" card.
import ChartLine from "../Charts/ChartLine";

export default {
  components: {
    ChartLine,
  },
  data() {
    return {
      // Data for line chart.
      lineChartData: {
        labels: [],
        datasets: [
          {
            label: "Sales",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#1890FF",
            borderWidth: 5,
            data: [],
            maxBarThickness: 20,
          },
        //   {
        //     label: "Websites",
        //     tension: 0.4,
        //     borderWidth: 0,
        //     pointRadius: 0,
        //     borderColor: "#B37FEB",
        //     borderWidth: 3,
        //     data: [180000],
        //     maxBarThickness: 20,
        //   },
        ],
      },

      datas: {
        date: [],
        total: [],
      },
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;

      await axios
        .get("admin/orderStat", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.stats = response.data.data.data;

          this.stats.forEach((value, index) => {
            this.datas.date.push(value["months"]);
            this.datas.total.push(value["total"]);
          });

          this.lineChartData.labels = this.datas.date.reverse();
          this.lineChartData.datasets[0].data = this.datas.total.reverse();
          console.log(this.lineChartData.datasets[0].data);

          this.$refs.chart.renderChart();
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
