<template>
  <!-- Active Users Card -->
  <a-card :bordered="false" class="dashboard-bar-chart">
    <template #title>
      <h6 style="text-transform: uppercase;">{{ type }} Overview</h6>
      <p>Grafik perkembangan {{ type }}</p>
      <div class="mt-25 text-center">
        <a-radio-group default-value="naskah" :value="type" @change="handleTypeChange" button-style="solid">
          <a-radio-button value="naskah"> Semua Naskah </a-radio-button>
          <a-radio-button value="market"> Naskah Market </a-radio-button>
          <a-radio-button value="publisher"> Publisher </a-radio-button>
        </a-radio-group>
      </div>
    </template>
    <chart-bar :height="300" :data="barChartData" ref="chart"></chart-bar>
  </a-card>
  <!-- Active Users Card -->
</template>

<script>
// Bar chart for "Active Users" card.
import ChartBar from "../Charts/ChartBar";
import axios from "axios";
export default {
  components: {
    ChartBar,
  },
  data() {
    return {
      // Data for bar chart.
      barChartData: {
        labels: [],
        datasets: [
          {
            label: "Naskah",
            backgroundColor: "#fff",
            borderWidth: 0,
            borderSkipped: false,
            borderRadius: 6,
            data: [],
            maxBarThickness: 20,
          },
        ],
      },

      datas: {
        date: [],
        total: [],
      },

      loading: false,
      type: "naskah",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleTypeChange(e) {
      this.type = e.target.value;
      this.$refs.chart.chart.destroy();

      this.datas.date = [];
      this.datas.total = [];
          
      this.barChartData.labels = [];
      this.barChartData.datasets[0].data = [];

      this.getData()
    },
    async getData() {
      this.loading = true;

      await axios
        .get("admin/naskahStat?type="+this.type, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.stats = response.data.data.data;

          this.stats.forEach((value, index) => {
            this.datas.date.push(value["date"]);
            this.datas.total.push(value["total"]);
          });

          this.barChartData.labels = this.datas.date.reverse();
          this.barChartData.datasets[0].data = this.datas.total.reverse();
          console.log(this.barChartData.datasets[0].data);

          this.$refs.chart.renderChart();
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
